import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  items: string[]
  title?: string
}

export const Info = memo(function Info({ items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title className="wave">{title}</Title>
          </FadeInUp>
        ) : null}

        <FadeInUp>
          <List>
            {items.map((item: any, index) => (
              <Item key={index}>{item.label}</Item>
            ))}
          </List>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 11.75rem;
  padding: 0 5vw;
  position: relative;
  &::before {
    content: '';
    display: block;
    height: 5.25rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1142.027" height="28.204" viewBox="0 0 1142.027 28.204"><path d="M0,.5C21.947.5,21.947,24.7,43.9,24.7S65.853.5,87.812.5,109.759,24.7,131.7,24.7,153.654.5,175.607.5s21.959,24.2,43.918,24.2S241.472.5,263.419.5s21.959,24.2,43.918,24.2S329.29.5,351.25.5,373.2,24.7,395.162,24.7,417.121.5,439.074.5,461.039,24.7,483,24.7,504.958.5,526.917.5s21.965,24.2,43.93,24.2S592.812.5,614.778.5s21.965,24.2,43.93,24.2S680.673.5,702.632.5,724.6,24.7,746.569,24.7,768.54.5,790.511.5c21.953,0,21.953,24.2,43.906,24.2S856.383.5,878.348.5s21.965,24.2,43.93,24.2S944.243.5,966.209.5s21.971,24.2,43.943,24.2S1032.129.5,1054.112.5s21.971,24.2,43.943,24.2S1120.038.5,1142.027.5" transform="translate(0 1.5)" fill="none" stroke="%23f7f6f4" stroke-miterlimit="10" stroke-width="4"/></svg>');
    position: absolute;
    top: 50%;
    right: 5vw;
    left: 5vw;
    transform: translateY(-50%);
  }

  @media (max-width: 1199px) {
    margin: 5.625rem auto;
    padding: 0 1.875rem;
    &::before {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  max-width: 50vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: auto;
  padding: 3.875rem 10.625vw;
  position: relative;
  text-align: center;
  z-index: 1;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const Title = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;

  @media (max-width: 1199px) {
    &::after {
      display: none !important;
    }
  }
`

const List = styled.div`
  margin-top: 2.625rem;
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  &:first-of-type {
    &:before {
      display: none;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark3};
    border-radius: 50%;
    margin: 1.5rem auto;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`
